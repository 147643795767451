import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Tabs, Tab } from 'react-bootstrap'

import logo from '../../img/troisprime-logo-fr.png'
import { RPContext } from '../../context/rp-context'
import Sessions from './Sessions'
import ControlPresentation from './ControlPresentation'
import AdminLogout from './AdminLogout'
import Polls from './Polls'
import Stats from './Stats'

const AdminControls = props => {
  const { sessionHelper, socket } = props;
  const { state } = useContext(RPContext);
  const { sessionID } = state;

  return (
    <div className="d-flex flex-column bg-light h-100">
      <Row style={{ height: '70px'}} className='d-flex justify-content-center align-items-center mb-3'><Col className='d-flex flex-column align-items-center'><img src={logo} className='img-fluid' alt='3 Prime'/></Col></Row>
      <Row className='pt-3 flex-fill'>
        <Col><div className='rounded h-100 d-flex justify-content-center' style={{ backgroundColor: '#526986' }}>
          {
            !sessionID || !sessionHelper
            ? <Sessions socket={socket} />
            : <Col className='p-0 h-100 admin-controls d-flex flex-column'>
                <Tabs defaultActiveKey='presentation' className='admin-controls-tabs rounded-top'>
                  <Tab eventKey='presentation' title={<i className='fa fa-desktop' aria-hidden='true' />} className='col h-100'><ControlPresentation session={sessionHelper.session} /></Tab>
                  <Tab eventKey='users' title={<i className='fa fa-users' aria-hidden='true' />} className='col h-100'>
                    <Col className='p-3 h-100 d-flex flex-column align-items-center rounded'>
                      <span className='text-light text-center mb-3'>Invite new users here</span>
                    </Col>
                  </Tab>
                  <Tab eventKey='stats' title={<i className='fa fa-chart-line' aria-hidden='true' />} className='col p-0 h-100'>
                    <Col className='p-0 h-100 d-flex flex-column align-items-center rounded'>
                      <Stats />
                    </Col>
                  </Tab>
                  <Tab eventKey='polls' title={<i className='fa fa-list-alt' aria-hidden='true' />} className='col h-100'>
                    <Col className='p-0 h-100 d-flex flex-column align-items-center rounded'>
                      <Polls sessionHelper={sessionHelper} socket={socket} />
                    </Col>
                  </Tab>
                  <Tab eventKey='leave' title={<i className='fa fa-sign-out-alt' aria-hidden='true' />} className='col h-100'><AdminLogout sessionHelper={sessionHelper} /></Tab>
                </Tabs>
              </Col>
          }
        </div></Col>
      </Row>
    </div>
  )
}
export default AdminControls;
AdminControls.propTypes = {
  sessionHelper: PropTypes.object,
  socket: PropTypes.object.isRequired,
}
