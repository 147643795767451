import React, { useCallback, useContext, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, ListGroup, Button, Tabs, Tab, Form } from 'react-bootstrap'
import { useCookies } from 'react-cookie'

import { RPContext, START_SESSION } from '../../context/rp-context'

const Sessions = props => {
  const { socket } = props;
  const { dispatch } = useContext(RPContext);
  // const { sessionID, sessionToken } = state;
  const [formValid, setFormValid] = useState(false)
  const [ready, setReady] = useState(false)
  const [sessions, setSessions] = useState([]);
  const sessName = useRef();
  const sessPwd = useRef();
  const [cookies, setCookie, removeCookie] = useCookies(['session', 'token']);

  const onSocketReady = useCallback(({ sessions }) => {
    setReady(true);
    setSessions(sessions);
  }, [setReady, setSessions]);

  const joinSession = useCallback((session, token = '') => {
    const onError = data => {
      if (data.error === 'session-not-found') {
        removeCookie('session')
        removeCookie('token');
      }
      socket.off('err', onError)
    }
    socket.off('err', onError)
    socket.on('err', onError)
    socket.emit('join-session', { from: socket.id, session, token, isAdmin: true })
  }, [socket, removeCookie])

  const onSessionJoined = useCallback(data => {
    const { session, token, name, datetime } = data;
    setCookie('session', session);
    setCookie('token', token);
    dispatch({ type: START_SESSION, payload: { id: session, token, name, datetime } })
  }, [setCookie, dispatch])

  const startSession = useCallback(e => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setFormValid(true);
      return
    }
    const { value: name } = sessName.current;
    const { value: password } = sessPwd.current;
    socket.emit('create-session', { name, password, from: socket.id });
  }, [setFormValid, socket, sessName, sessPwd])

  useEffect(() => {
    if (!socket) {
      return
    }
    socket.on('ready', onSocketReady);
    socket.on('error', console.log);
    socket.on('session-created', joinSession);
    socket.on('sessions', setSessions);
    socket.on('session-joined', onSessionJoined);
    return () => {
      socket.off('ready', onSocketReady);
      socket.off('error', console.log);
      socket.off('session-created', joinSession);
      socket.off('sessions', setSessions);
      socket.off('session-joined', onSessionJoined);
    }
  }, [socket, onSocketReady, joinSession, setSessions, setReady, onSessionJoined])

  // If the user was already connected before refreshing the page, reconnect him to the session
  useEffect(() => {
    const { session, token } = cookies
    if (ready && socket && session && token) {
      joinSession(session, token)
    }
  }, [ready, cookies, socket, joinSession])

  return (
    <Col className='p-0 h-100'>
      <Tabs defaultActiveKey='new' className='admin-session-tabs rounded-top'>
        <Tab eventKey='list' title={<i className='fa fa-list' aria-hidden='true' />}>
          {sessions.length > 0 &&
          <ListGroup>
            { sessions.map(session => <ListGroup.Item key={session.id} className='overflow-hidden not-rounded' style={{ cursor: 'pointer' }} onClick={() => joinSession(session.id)} >
              { session.name }
            </ListGroup.Item>) }
          </ListGroup>
          }
        </Tab>
        <Tab eventKey='new' title={<i className='fa fa-plus' aria-hidden='true' />} className='p-3 d-flex flex-column align-items-center'>
          <span className='text-light text-center mb-3 font-weight-bold'>Créer une nouvelle session</span>
          <Form className='d-flex w-100 flex-column align-items-center' action='#' onSubmit={startSession} noValidate validated={formValid}>
            <Form.Group className='w-100' controlId='session-name'>
              <Form.Label className='text-light'>Nom de session</Form.Label>
              <Form.Control ref={sessName} required type='text' placeholder='Session' />
              <Form.Control.Feedback type="invalid">Entrez un nom</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='w-100' controlId='password'>
              <Form.Label className='text-light'>Mot de passe</Form.Label>
              <Form.Control ref={sessPwd} required type='password' placeholder='Mot de passe' />
              <Form.Control.Feedback type="invalid">Entrez un mot de passe</Form.Control.Feedback>
            </Form.Group>
            <Button disabled={!ready} variant='primary' type='submit'>Commencer</Button>
          </Form>
        </Tab>
      </Tabs>
    </Col>
  )
}

export default Sessions
Sessions.propTypes = {
  socket: PropTypes.object.isRequired,
}