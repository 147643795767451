import React, { useContext, useEffect, useState } from 'react'

import { RPContext } from '../context/rp-context'
import { Col, Row } from 'react-bootstrap'
import { PRESENTATION_STEPS } from '../Constants'

const Presentation = () => {
  const { state } = useContext(RPContext);
  const { presentation } = state;
  const { step } = presentation;
  const [CurrentStep, setCurrentStep] = useState(null)

  useEffect(() => {
    setCurrentStep(PRESENTATION_STEPS[step].component);
  }, [step, setCurrentStep])

  return (
    <Col className='h-100 d-flex flex-column pt-3 pb-3'>
      <Row className='rounded d-flex overflow-auto h-100' style={{ maxHeight: 'calc(100% - 35px)' }}>
        { CurrentStep !== null && CurrentStep }
      </Row>
      <Row className='mt-3' style={{ height: 35, userSelect: 'none' }}><div className='rounded h-100 w-100 d-flex justify-content-center align-items-center font-weight-bold' style={{ backgroundColor: '#f6a126', color: '#FFF', cursor: 'pointer' }}>{ PRESENTATION_STEPS[step].title }</div></Row>
    </Col>
  )
}

export default Presentation