import React from 'react';
import { PRESENTATION_STEPS } from '../Constants';

export const RPContext = React.createContext();

export const START_SESSION = 'START_SESSION';
export const END_SESSION = 'END_SESSION';
export const ADD_VIEWER = 'ADD_VIEWER';
export const REMOVE_VIEWER = 'REMOVE_VIEWER';
export const VIEWER_REQUEST_SPEAK = 'VIEWER_REQUEST_SPEAK';
export const SET_SPEAKER = 'SET_SPEAKER';

export const NEXT_STEP = 'NEXT_STEP';
export const PREV_STEP = 'PREV_STEP';
export const SET_STEP = 'SET_STEP';
export const SHOW_POLL = 'SHOW_POLL';
export const REMOVE_POLL = 'REMOVE_POLL';
export const UPDATE_POLL = 'UPDATE_POLL';
export const SET_POLLS = 'SET_POLLS';

export const initialState = {
  sessionID: '',
  datetimeStart: null,
  sessionToken: '',
  sessionName: '',
  viewers: [],
  maxViewers: 0,
  polls: [],
  speaker: null,
  presentation: {
    step: 0
  }
};

const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case START_SESSION:
      return {
        ...state,
        sessionID: payload.id,
        sessionToken: payload.token,
        sessionName: payload.name,
        datetimeStart: payload.datetime ? new Date(payload.datetime) : new Date(),
      }
    case END_SESSION:
      return {
        ...initialState
      }
    case ADD_VIEWER:
      return {
        ...state,
        viewers: [...state.viewers, payload],
        maxViewers: state.viewers.length >= state.maxViewers ? state.viewers.length + 1 : state.maxViewers
      }
    case REMOVE_VIEWER:
      return {
        ...state,
        viewers: state.viewers.filter(viewer => viewer.id !== payload),
        speaker: state.speaker === payload ? null : state.speaker
      }
    case VIEWER_REQUEST_SPEAK:
      return {
        ...state,
        viewers: state.viewers.map(viewer => viewer.id === payload.id ? { ...viewer, requestingSpeak: payload.request } : viewer)
      }
    case SET_SPEAKER:
      return {
        ...state,
        speaker: payload
      }
    
    case NEXT_STEP:
      return state.presentation.step === PRESENTATION_STEPS.length - 1 ? state : {
        ...state,
        presentation: { ...state.presentation, step: state.presentation.step + 1 }
      }
    case PREV_STEP:
      return state.presentation.step === 0 ? state : {
        ...state,
        presentation: { ...state.presentation, step: state.presentation.step - 1 }
      }
    case SET_STEP:
      return {
        ...state,
        presentation: { ...state.presentation, step: payload }
      }
    case SET_POLLS:
      return {
        ...state,
        polls: payload
      }
    case SHOW_POLL:
      return {
        ...state,
        polls: [...state.polls, payload]
      }
    case REMOVE_POLL:
      return {
        ...state,
        polls: state.polls.filter(poll => poll.id !== payload)
      }
    case UPDATE_POLL:
      return {
        ...state,
        polls: state.polls.map(poll => poll.id !== payload.id ? poll : payload)
      }
    default:
      return state;
  }
}

export default reducer;

export const addViewer = (id, data, isMe) => dispatch => {
  const { name } = data;
  dispatch({
    type: ADD_VIEWER,
    payload: { id, name, isMe }
  })
}

export const removeViewer = (id) => dispatch => {
  dispatch({
    type: REMOVE_VIEWER,
    payload: id
  })
}

export const setStep = (step) => dispatch => {
  if (step < 0 || step >= PRESENTATION_STEPS.length) {
    return;
  }
  dispatch({
    type: SET_STEP,
    payload: step
  })
}