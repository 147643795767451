import React, { useEffect, useContext, useCallback, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, InputGroup, FormControl, Accordion, Card, Form } from 'react-bootstrap'
import { useCookies } from 'react-cookie'

import { RPContext, START_SESSION, SET_SPEAKER } from '../context/rp-context'

const ViewerLogin = props => {
  const { socket } = props;
  const { dispatch } = useContext(RPContext);
  const [sessions, setSessions] = useState([]);
  const [ready, setReady] = useState(false)
  const accordion = useRef();
  const [cookies, setCookie, removeCookie] = useCookies(['session', 'token']);

  const onSocketReady = useCallback(({ sessions: s }) => {
    setReady(true);
    setSessions(s);
  }, [setReady, setSessions]);

  const joinSession = useCallback(async (session, username, password, token = '') => {
    const onError = data => {
      if (data.error === 'session-not-found') {
        removeCookie('session')
        removeCookie('token');
      }
      socket.off('error', onError)
    }
    socket.off('error', onError)
    socket.on('error', onError)
    socket.emit('join-session', { from: socket.id, session, username, password, token })
  }, [removeCookie, socket])

  const onSessionJoined = useCallback(data => {
    const { session, name, token, speaker } = data;
    dispatch({ type: START_SESSION, payload: { id: session, token, name } })
    if (!!speaker) {
      dispatch({ type: SET_SPEAKER, payload: speaker });
    }
    if (process.env.NODE_ENV !== 'development') {
      setCookie('session', session);
      setCookie('token', token);
    }
  }, [setCookie, dispatch])

  const loginToSession = useCallback(e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation()
    }
    const activeCard = accordion.current.querySelector('.collapse.show');
    if (!activeCard) {
      return;
    }
    const { value: user } = activeCard.querySelector('input#username');
    const { value: pwd } = activeCard.querySelector('input#pwd');
    if (user.match(/^\s*$/) || pwd.match(/^\s*$/)) {
      return;
    }
    const sessionID = activeCard.parentNode.dataset.id;
    joinSession(sessionID, user, pwd);
  }, [accordion, joinSession])

  const onInputKeyDown = useCallback(e => e.key === 'Enter' && loginToSession(), [loginToSession]);

  useEffect(() => {
    if (!socket) {
      return;
    }
    socket.on('ready', onSocketReady);
    socket.on('error', console.log)
    socket.on('sessions', setSessions);
    socket.on('session-joined', onSessionJoined)
    return () => {
      socket.off('ready', onSocketReady);
      socket.off('error', console.log)
      socket.off('sessions', setSessions);
      socket.off('session-joined', onSessionJoined)
    }
  }, [socket, joinSession, setReady, setSessions, onSessionJoined, onSocketReady])
  
  /**
   * If the user was previously in a session, try to join it again
   */
  useEffect(() => {
    const { session, token } = cookies;
    if (session && token) {
      joinSession(session, token)
    }
  }, [cookies, joinSession]);

  return (
    <div className='position-fixed container-fluid cover-fill' style={{ top: 0, left: 0, bottom: 0, right: 0 }}>
      <Modal centered backdrop='static' keyboard={false} show={true} style={{ maxHeight: 'calc(100vh - 225px)' }}>
        <Modal.Header>
          <Modal.Title>Rejoindre une session</Modal.Title>
        </Modal.Header>

        <Modal.Body className='overflow-auto' style={{ maxHeight: 'calc(100vh - 225px)' }}>
          <Accordion ref={accordion}>
            {sessions.length === 0
              ? <p>Aucune session disponible pour le moment</p>
              : sessions.map((session, i) =>
                <Card key={session.id} data-id={session.id} >
                  <Accordion.Toggle as={Card.Header} eventKey={i} style={{ cursor: 'pointer' }}>
                    {session.name}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={i}>
                    <Form className='col' action='#' onSubmit={loginToSession}>
                      <InputGroup className='mb-3 mt-3'>
                        <InputGroup.Prepend><InputGroup.Text id='username-addon'>Votre nom</InputGroup.Text></InputGroup.Prepend>
                        <FormControl id='username' type='text' required onKeyDown={onInputKeyDown} aria-describedby='username-addon' />
                      </InputGroup>
                      <InputGroup className='mb-3 mt-3'>
                        <InputGroup.Prepend><InputGroup.Text id='pwd-addon'>Mot de passe</InputGroup.Text></InputGroup.Prepend>
                        <FormControl id='pwd' type='password' required onKeyDown={onInputKeyDown} aria-describedby='pwd-addon' />
                      </InputGroup>
                    </Form>
                  </Accordion.Collapse>
                </Card>
              )}
          </Accordion>
        </Modal.Body>

        <Modal.Footer>
          <Button disabled={sessions.length === 0 || !ready} onClick={loginToSession} variant='primary'>Rejoindre</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ViewerLogin
ViewerLogin.propTypes = {
  socket: PropTypes.object.isRequired,
}
