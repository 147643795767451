import React, { useContext, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { Container, Col, Row } from 'react-bootstrap'
import io from 'socket.io-client'

import StreamColumn from './StreamColumn'
import { RPContext, setStep, SHOW_POLL, SET_POLLS, REMOVE_POLL } from '../context/rp-context'
import ViewerLogin from './ViewerLogin'
import Presentation from './Presentation'
import { PRESENTATION_STEPS } from '../Constants'

const API_URL = `${process.env.REACT_APP_SSL_ENABLED === 'true' ? 'https' : 'http'}://${process.env.REACT_APP_API_URI}:${process.env.REACT_APP_PORT}/`

const Participant = props => {
  const { sessionHelper, streams} = props;
  const { state, dispatch } = useContext(RPContext);
  const { sessionID } = state;
  const socket = useRef();

  const onSignalStep = useCallback(e => {
    if (typeof e.data === 'number') {
      const { data: step } = e;
      if (step < 0 || step >= PRESENTATION_STEPS.length) {
        return;
      }
      setStep(step)(dispatch)
    }
  }, [dispatch])

  const showPoll = useCallback(poll => {
    dispatch({ type: SHOW_POLL, payload: poll })
  }, [dispatch])

  const closePoll = useCallback(id => {
    dispatch({ type: REMOVE_POLL, payload: id })
  }, [dispatch])

  const onSocketReady = useCallback(({ polls, sessions }) => {
    dispatch({ type: SET_POLLS, payload: polls })
  }, [dispatch])

  useEffect(() => {
    if (!sessionHelper) {
      return;
    }
    sessionHelper.session.on('signal:presentation-step', onSignalStep)
    return () => {
      sessionHelper.session.off('signal:presentation-step', onSignalStep)
    };
  }, [sessionHelper, onSignalStep]);

  useEffect(() => {
    socket.current = io(API_URL);
    socket.current.on('ready', onSocketReady)
    socket.current.on('vote-closed', closePoll)
    socket.current.on('show-vote', showPoll);
    return () => {
      socket.current.off('ready', onSocketReady);
      socket.current.off('vote-closed', closePoll)
      socket.current.off('show-vote', showPoll);
    }
  }, [socket, onSocketReady, closePoll, showPoll]);

  return (
    <Container className="h-100 bg-white" fluid>
      <Row className="h-100">
        <Col className="h-100 bg-light" md={7}>{sessionID && <Presentation />}</Col>
        <Col className="h-100 p-3 bg-light shadow" md={5}><StreamColumn sessionHelper={sessionHelper} streams={streams} socket={socket.current} /></Col>
        { !sessionID && socket.current && <ViewerLogin socket={socket.current} /> }
      </Row>
    </Container>
  )
}

export default Participant
Participant.propTypes = {
  sessionHelper: PropTypes.object,
  streams: PropTypes.array,
}