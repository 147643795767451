import React, { useCallback, useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Button, InputGroup, FormControl, Dropdown } from 'react-bootstrap'
import { v4 as uuid } from 'uuid';
import { OTPublisher } from 'opentok-react';

const Chat = props => {
  const { session, isAdmin, isSpeaking } = props;
  const input = useRef(null);
  const chatList = useRef(null);
  const [messages, setMessages] = useState([]);
  const [isRequestingSpeak, setIsRequestingSpeak] = useState(false);

  const sendMessage = useCallback(() => {
    if (!input.current) {
      return;
    }
    const { value } = input.current
    if (value.match(/^\s*$/)) {
      return;
    }
    session.signal({
      type: 'msg',
      data: { id: uuid(), text: input.current.value }
    }, error => {
      if (error) {
        console.log('Error sending signal:', error.name, error.message);
      } else {
        input.current.value = '';
      }
    });
  }, [input, session])

  const removeMsg = useCallback(id => {
    session.signal({
      type: 'msg-remove',
      data: id
    }, error => {
      if (error) {
        console.log('Error sending signal:', error.name, error.message);
      }
    })
  }, [session])

  const onInputKeyDown = useCallback(e => (e.key === 'Enter') && sendMessage(), [sendMessage])

  const onMessageReceived = useCallback(e => {
    const { data, from } = e;
    const { text, id } = data;
    const username = JSON.parse(from.data).name;
    setMessages([...messages, { id: id, from: from.id === session.connection.id ? 'You' : username, text: text}])
  }, [messages, setMessages, session])

  const onMessageRemoved = useCallback(e => {
    setMessages(messages.filter(msg => msg.id !== e.data))
  }, [messages, setMessages])

  const requestSpeak = useCallback(() => {
    if (!isRequestingSpeak) {
      session.signal({ type: 'request-speak' }, error => {
        if (error) {
          console.log(error)
        } else {
          setIsRequestingSpeak(true)
        }
      })
    } else {
      session.signal({ type: 'cancel-request-speak' }, error => {
        if (error) {
          console.log(error)
        }
        setIsRequestingSpeak(false)
      })
    }
  }, [session, isRequestingSpeak, setIsRequestingSpeak])

  const stopSpeaking = useCallback(() => {
    session.signal({ type: 'cancel-request-speak' }, error => {
      if (error) {
        console.log(error)
      }
      setIsRequestingSpeak(false)
    })
  }, [session, setIsRequestingSpeak])

  const onSpeakRequestReplied = useCallback(() => setIsRequestingSpeak(false), [setIsRequestingSpeak])

  const videoElementCreated = useCallback(e => {
    const container = e.element.parentNode;
    const alert = document.createElement('div');
    alert.innerText = 'Vous avez la parole';
    alert.classList.add('alert', 'alert-success');
    container.appendChild(alert);
  }, []);

  /**
   * When the messages are updated, scroll the list down
   */
  useEffect(() => {
    if (chatList.current) {
      chatList.current.scroll({
        top: chatList.current.scrollHeight, 
        left: 0, 
        behavior: 'smooth'
      });
    }
  }, [messages, chatList]);
  
  useEffect(() => {
    if (!session) {
      return;
    }
    session.on('signal:msg', onMessageReceived)
    session.on('signal:msg-remove', onMessageRemoved)
    session.on('signal:allow-speak', onSpeakRequestReplied)
    session.on('signal:deny-speak', onSpeakRequestReplied)
    session.on('signal:stop-speak', onSpeakRequestReplied)
    return () => {
      session.off('signal:msg', onMessageReceived)
      session.off('signal:msg-remove', onMessageRemoved)
      session.off('signal:allow-speak', onSpeakRequestReplied)
      session.off('signal:deny-speak', onSpeakRequestReplied)
      session.off('signal:stop-speak', onSpeakRequestReplied)
  }
  }, [session, onMessageReceived, onMessageRemoved, onSpeakRequestReplied])

  return (
    <Col className='bg-secondary h-100 rounded d-flex flex-column p-3 chat'>
      <Col ref={chatList} className='h-100 bg-dark d-flex flex-column rounded list-group overflow-auto p-0'>
        { messages.map((msg, i) =>
          <span key={i} className='list-group-item list-group-item-info message position-relative overflow-hidden flex-shrink-0' style={{ wordBreak: 'break-all' }}>
            {`${msg.from}: ${msg.text}`}
            { isAdmin && <Button className='btn-danger position-absolute' style={{ top: '0.05rem', right: '0.15rem' }} onClick={() => removeMsg(msg.id)}><i className='fas fa-times'></i></Button>}
          </span>
        )}
      </Col>
      <InputGroup style={{ height: 38 }} className='mt-3'>
        <FormControl type='text' ref={input} onKeyDown={onInputKeyDown} placeholder='Message' aria-label='Message' autoComplete='new-password' />
        <InputGroup.Append><Button className='input-group-text' onClick={sendMessage}>Envoyer</Button></InputGroup.Append>
      </InputGroup>
      {
        !isAdmin && <React.Fragment>
          <Dropdown.Divider />
          {
            /** If the viewer is set as speaking, add a publisher for the audio */
            !isAdmin && isSpeaking && <div className='viewer-audio-publisher'>
              { <OTPublisher session={session}
                style={{ width: '100%', height: 50, overflow: 'hidden' }}
                className='rounded mb-3 flex-shrink-0'
                eventHandlers={{ videoElementCreated }}
                properties={{ width: '100%', height: 50, videoSource: null, publishVideo: false }}
              /> }
            </div>
          }  
          <Button variant='dark' onClick={isSpeaking ? stopSpeaking : requestSpeak}>{ isSpeaking ? 'Rendre la parole' : isRequestingSpeak ? 'Annuler la demande' : 'Demander la parole'}</Button>
        </React.Fragment>
      }
    </Col>
  )
}

Chat.propTypes = {
  session: PropTypes.object,
  isAdmin: PropTypes.bool,
  isSpeaking: PropTypes.bool,
}
export default Chat