import Home from './rp/Home'
import Page from './rp/Page'
import Conclusion from './rp/Conclusion'
import Game from './rp/Game'

export const PRESENTATION_STEPS = [
  { title: 'Contexte clinique', component: Home },
  { title: 'Contexte clinique 2', component: Page },
  { title: 'Échocardiographie transthoracique', component: Conclusion },
  { title: 'Game', component: Game },
];