import React, { useEffect, useState, useReducer } from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { createSession } from 'opentok-react'

import './App.css'
import Admin from './components/Admin'
import Participant from './components/Participant'
import reducer, { RPContext, initialState } from './context/rp-context'

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { sessionID, sessionToken } = state;
  const [streams, setStreams] = useState([]);
  const [sessionHelper, setSessionHelper] = useState(null);

  /**
   * Once we get the session ID and token from the API, connect to the OpenTok session
   */
  useEffect(() => {
    if (!sessionID || !sessionToken) {
      setSessionHelper(null);
      setStreams([]);
      return;
    }
    const helper = createSession({
      apiKey: '46594492',
      sessionId: sessionID,
      token: sessionToken,
      onStreamsUpdated: s => setStreams(s),
    })
    setSessionHelper(helper);

    return () => helper && helper.disconnect();
  }, [sessionID, sessionToken, setSessionHelper])

  return (
    <RPContext.Provider value={{ state, dispatch }}>
      <BrowserRouter><Switch>
        <Route path="/admin">
          <Admin sessionHelper={sessionHelper} streams={streams} />
        </Route>
        <Route path="/">
          <Participant sessionHelper={sessionHelper} streams={streams} />
        </Route>
      </Switch></BrowserRouter>
    </RPContext.Provider>
  );
}

export default App;
