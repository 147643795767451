import React from 'react'
import { Col } from 'react-bootstrap'

import '../fonts/stylesheet.css'
import bg from '../img/background.png'
import ett from '../img/ett.png'

const Conclusion = () => {
  return (
    <Col style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', fontFamily: 'Avenir' }} className='h-100 overflow-auto bg-info text-white p-4'>
      <h1>Échocardiographie transthoracique</h1>
      <hr/>
      <h4>L'échographie cardiaque met en évidence&nbsp;:</h4>
      <img src={ett} alt='ETT' />
      <ul className='mt-3'>
        <li>Une bonne fonction systolique du ventricule gauche (VG) avec une fraction d'éjection du ventricule gauche (FEVG) à 60%</li>
        <li>Les pressions de remplissage du VG sont difficiles à évaluer</li>
        <li>Dilatation des cavités droites</li>
        <li>Pression artérielle pulmonaire systolique&nbsp;: PAPs = 70mmHg</li>
      </ul>
    </Col>
  )
}

export default Conclusion
