import React, { useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Col, Button } from 'react-bootstrap'
import { useCookies } from 'react-cookie'

import { RPContext, END_SESSION } from '../../context/rp-context'

const AdminLogout = props => {
  const { sessionHelper } = props;
  const { state, dispatch } = useContext(RPContext);
  const { sessionID } = state
  const [,, removeCookie] = useCookies(['session', 'token']);

  /**
   * Leave and close the session, disconnect all the players
   */
  const endSession = useCallback(() => {
    if (!sessionID) {
      return;
    }
    removeCookie('session');
    removeCookie('token');
    sessionHelper.disconnect();
    dispatch({ type: END_SESSION });
  }, [sessionID, dispatch, removeCookie, sessionHelper])

  return (
    <Col className='p-3 h-100 d-flex flex-column align-items-center rounded'>
      <span className='text-light text-center mb-3 font-weight-bold'>Voulez-vous fermer cette session ?</span>
      <span className='text-light text-center mb-3 font-weight-light'>Toutes les données seront perdues</span>
      <Button onClick={endSession}>Fermer la session</Button>
    </Col>
  )
}

export default AdminLogout
AdminLogout.propTypes = {
  sessionHelper: PropTypes.object.isRequired,
}