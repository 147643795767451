import React from 'react'
import { Col } from 'react-bootstrap'

import '../fonts/stylesheet.css'
import bg from '../img/background.png'

const Home = () => {
  return (
    <Col style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', fontFamily: 'Avenir' }} className='h-100 overflow-auto bg-info text-white p-4'>
      <h1>Contexte clinique</h1>
      <hr/>
      <h3>Patient SA, 56 ans</h3><br/>
      <h4>Antécédents</h4>
      <ul>
        <li>Obésité (IMC 32 kg/m2)</li>
        <li>Splénectomie post-traumatique en 2003</li>
        <li>Syndrome d'apnée obstructives du sommeil appareillé par ventilation en pression positive continue (CPAP) depuis 2010</li>
        <li>Pas dantécédent documenté de maladie thromboembolique veineuse</li>
      </ul>
      <h4>Prise en charge en septembre 2011 pour embolie pulmonaire «&nbsp;aiguë&nbsp;»</h4>
      <ul>
        <li>Dyspnée d'effort croissante évoluant depuis 6 mois</li>
        <li>Depuis plusieurs épisodes de «&nbsp;bronchite&nbsp;»</li>
      </ul>
    </Col>
  )
}

export default Home
