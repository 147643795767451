import React, { useContext, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { RPContext, REMOVE_POLL } from '../context/rp-context'
import { Form, Button } from 'react-bootstrap'

const VotePoll = props => {
  const { state, dispatch } = useContext(RPContext);
  const { socket } = props;
  const { polls, sessionID } = state;
  const [poll, setPoll] = useState(null)
  const [checkedOption, setCheckedOption] = useState(-1);

  const submitPoll = useCallback(e => {
    e.preventDefault()
    socket.emit('answer-vote', { from: socket.id, room: sessionID, poll: { id: poll.id, answer: checkedOption } });
    dispatch({ type: REMOVE_POLL, payload: poll.id });
    setCheckedOption(-1)
  }, [socket, checkedOption, setCheckedOption, dispatch, poll, sessionID])

  useEffect(() => {
    setPoll(polls[0]);
  }, [polls, setPoll])
  
  return !!poll && (
    <Form className='col position-absolute top-0 rounded bg-info text-center p-3' style={{ zIndex: 10, width: 'calc(100% - 2rem + 1px)' }} action='#' onSubmit={submitPoll}>
      <span className='font-weight-bold'>{ poll.question }</span>
      {
        poll.options.map((option, i) => 
          <Form.Check 
            key={`${poll.id}-${i}`}
            type='radio'
            id={`${i}`}
            onChange={() => setCheckedOption(i)}
            label={option}
            name='vote'
            className='w-100 d-flex'
          />
        )
      }
      <Button className='mt-3' type='submit'>Voter</Button>
    </Form>
  )
}

export default VotePoll
VotePoll.propTypes = {
  socket: PropTypes.object,
}