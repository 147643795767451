import React, { useContext, useState, useEffect } from 'react';
import { Table, Col } from 'react-bootstrap'

import { RPContext } from '../../context/rp-context'

const Stats = () => {
  const { state } = useContext(RPContext);
  const { datetimeStart, maxViewers, viewers } = state;
  const [timeSinceStart, setTimeSinceStart] = useState(0);

  useEffect(() => {
    if (timeSinceStart === 0) {
      setTimeSinceStart(((Date.now() - datetimeStart.getTime()) / 1000) | 0);
    }
    const timeout = setTimeout(() => setTimeSinceStart(((Date.now() - datetimeStart.getTime()) / 1000) | 0), 1000);
    return () => clearTimeout(timeout);
  }, [timeSinceStart, setTimeSinceStart, datetimeStart]);

  return (
    <Col variant="flush" className='h-100 w-100 p-0 d-flex flex-column list-group overflow-auto rounded'>
      <Table style={{ maxHeight: '100%', color: '#FFF' }} variant='dark' striped hover>
      <tbody>
        <tr><td>Heure de début</td><td>{datetimeStart.toLocaleTimeString()}</td></tr>
        <tr><td>Durée de la session</td><td>{new Date(1000 * timeSinceStart).toISOString().substr(11, 8)}</td></tr>
        <tr><td>Nombre de visionnages en cours</td><td>{viewers.length}</td></tr>
        <tr><td>Nombre de visionnages record</td><td>{maxViewers}</td></tr>
      </tbody>
    </Table>
    </Col>
  )
}

export default Stats
