import React, { useContext, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Row, ListGroup } from 'react-bootstrap'
import classnames from 'classnames'

import { RPContext, setStep } from '../../context/rp-context'
import { PRESENTATION_STEPS } from '../../Constants'

const ControlPresentation = props => {
  const { session } = props;
  const { state, dispatch } = useContext(RPContext);
  const { presentation } = state;
  const { step: currentStep } = presentation;

  const goToStep = useCallback(step => {
    if (step < 0 || step >= PRESENTATION_STEPS.length) {
      return;
    }
    // Send signal to all
    setStep(step)(dispatch);
    session.signal({ type: 'presentation-step', data: step })
  }, [dispatch, session])
  const next = useCallback(() => goToStep(currentStep + 1), [goToStep, currentStep])
  const back = useCallback(() => goToStep(currentStep - 1), [goToStep, currentStep])
  const clickStep = useCallback(e => goToStep(parseInt(e.currentTarget.dataset.id)), [goToStep])

  const onConnectionCreated = useCallback(e => {
    session.signal({ type: 'presentation-step', to: e.connection, data: currentStep })
  }, [session, currentStep]);

  useEffect(() => {
    if (!session) {
      return;
    }
    session.on('connectionCreated', onConnectionCreated)
    return () => {
      session.off('connectionCreated', onConnectionCreated)
    };
  }, [session, onConnectionCreated]);

  return (
    <Col className='p-0 pt-3 d-flex flex-column h-100'>
      <Row className='d-flex pr-3 pl-3'>
        <Button className='mr-3' style={{ flex: 1 }} onClick={back}><i className='fa fa-step-backward' aria-hidden='true'></i></Button>
        <Button style={{ flex: 1 }} onClick={next}><i className='fa fa-step-forward' aria-hidden='true'></i></Button>
      </Row>
      <Col variant="flush" className='h-100 w-100 mt-3 mb-3 p-0 d-flex flex-column list-group overflow-auto rounded'>
        { PRESENTATION_STEPS.map((step, i) => 
          <ListGroup.Item key={i} data-id={i} onClick={clickStep} style={{ cursor: 'pointer' }} className={classnames({ active: i === currentStep }, 'flex-shrink-0 flex-grow-1 d-flex align-items-center')}>{step.title}</ListGroup.Item>
        )}
      </Col>
    </Col>
  )
}

ControlPresentation.propTypes = {
  session: PropTypes.object.isRequired,
}
export default ControlPresentation;