import React, { useEffect, useState, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'

import '@opentok/client'
import { OTPublisher, OTSubscriber } from 'opentok-react'

import { RPContext, addViewer, removeViewer, SET_SPEAKER } from '../context/rp-context'
import Viewers from './Viewers'
import Chat from './Chat'
import VotePoll from './VotePoll'

const StreamColumn = props => {
  const { isAdmin, sessionHelper, streams, socket } = props;
  const { state, dispatch } = useContext(RPContext);
  const { sessionName, polls } = state;
  const [isSpeaking, setIsSpeaking] = useState(false);
  
  /**
   * When a user connects to the session
   */
  const onConnectionCreated = useCallback((e) => {
    const { connection } = e;
    if (isAdmin && connection.id === sessionHelper.session.connection.id) {
      return
    }
    const { id, data } = connection;
    addViewer(id, JSON.parse(data), sessionHelper.session.connection.id === id)(dispatch)
  }, [dispatch, sessionHelper, isAdmin])
  
  /**
   * When a user disconnects from the session
   */
  const onConnectionDestroyed = useCallback((e) => {
    removeViewer(e.connection.id)(dispatch)
  }, [dispatch])

  /**
   * When the admin has allowed the user to speak
   */
  const onAllowSpeak = useCallback(data => {
    const { speaker } = data;
    if (sessionHelper.session.connection.id === speaker) {
      setIsSpeaking(true);
    }
    dispatch({ type: SET_SPEAKER, payload: speaker });
  }, [setIsSpeaking, dispatch, sessionHelper])

  /**
   * When the admin has denied the user to speak
   */
  const onDenySpeak = useCallback(() => {
    setIsSpeaking(false);
  }, [setIsSpeaking])

  /**
   * When the admin has stopped allowing the user to speak
   */
  const onStopSpeak = useCallback(() => {
    setIsSpeaking(false);
    dispatch({ type: SET_SPEAKER, payload: null });
  }, [setIsSpeaking, dispatch])

  useEffect(() => {
    if (!sessionHelper) {
      return;
    }
    const { session } = sessionHelper;
    session.on('connectionCreated', onConnectionCreated)
    session.on('connectionDestroyed', onConnectionDestroyed)
    if (!isAdmin && socket) {
      socket.on('allow-speak', onAllowSpeak)
      socket.on('deny-speak', onDenySpeak)
      socket.on('stop-speak', onStopSpeak)
    }
    return () => {
      session.off('connectionCreated', onConnectionCreated)
      session.off('connectionDestroyed', onConnectionDestroyed)
      if (!isAdmin && socket) {
        socket.off('allow-speak', onAllowSpeak)
        socket.off('deny-speak', onDenySpeak)
        socket.off('stop-speak', onStopSpeak)
      }
    }
  }, [sessionHelper, socket, isAdmin, onConnectionCreated, onConnectionDestroyed, onAllowSpeak, onDenySpeak, onStopSpeak]);

  return (
    <div className='d-flex flex-column bg-light h-100 stream-column position-relative rounded'>
      <Row className='h-100'>
        <Col className='pr-3 position-relative'>
          { !isAdmin && polls.length > 0 && sessionHelper && sessionHelper.session && <VotePoll socket={socket} /> }
          <Chat session={sessionHelper && sessionHelper.session} isSpeaking={isSpeaking} isAdmin={isAdmin} socket={socket} />
        </Col>
        <Col className='d-flex flex-column pl-0'>
          {
            process.env.REACT_APP_STREAM_ENABLED === 'true' && isAdmin && sessionHelper && <OTPublisher session={sessionHelper.session}
              style={{ width: '100%', overflow: 'hidden' }}
              className='rounded mb-3 flex-shrink-0'
              properties={{ width: '100%' }}
            />
          }
          { sessionHelper && streams.map(stream => 
            <OTSubscriber style={{ width: '100%', overflow: 'hidden', display: (isAdmin || !stream.hasVideo) ? 'none' : 'block' }} className='rounded mb-3 flex-shrink-0' properties={{ width: '100%' }} key={stream.id} session={sessionHelper.session} stream={stream} />
          ) }
          <Viewers session={sessionHelper && sessionHelper.session} socket={socket} isAdmin={isAdmin} />
        </Col>
      </Row>
      <Row className='mt-3' style={{ height: 35, userSelect: 'none' }}><Col><div className='rounded h-100 d-flex justify-content-center align-items-center font-weight-bold' style={{ backgroundColor: '#f6a126', color: '#FFF', cursor: 'pointer' }}>{sessionName}</div></Col></Row>
    </div>
  )
}

export default StreamColumn
StreamColumn.propTypes = {
  sessionHelper: PropTypes.object,
  isAdmin: PropTypes.bool,
  streams: PropTypes.array,
  socket: PropTypes.object,
}