import React, { useEffect, useContext, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button, Accordion, Card, Form, InputGroup, Row, Dropdown } from 'react-bootstrap'

import { RPContext, SHOW_POLL, SET_POLLS, UPDATE_POLL } from '../../context/rp-context'
import classnames from 'classnames'

const Polls = props => {
  const { socket, sessionHelper } = props;
  const { state, dispatch } = useContext(RPContext);
  const { polls } = state;
  const question = useRef();
  const [options, setOptions] = useState([]);
  const [addingPoll, setAddingPoll] = useState(false)

  const addOption = useCallback(() => {
    setOptions([...options, ''])
  }, [options, setOptions])

  const updateOption = useCallback((id, value) => {
    setOptions(options.map((option, i) => i !== id ? option : value))
  }, [options, setOptions])

  const removeOption = useCallback(id => {
    setOptions(options.filter((_, i) => i !== id))
  }, [options, setOptions])

  const createPoll = useCallback(e => {
    e.preventDefault()
    if (!question.current.value) {
      return;
    }
    const poll = {
      question: question.current.value,
      options, answers: options.map(() => [])
    }
    socket.emit('create-vote', { poll, room: sessionHelper.session.id })
    setAddingPoll(false);
    setOptions([]);
  }, [socket, options, question, sessionHelper, setAddingPoll, setOptions])

  const onPollAdded = useCallback(poll => dispatch({ type: SHOW_POLL, payload: poll }), [dispatch])
  const updatePolls = useCallback(polls => dispatch({ type: SET_POLLS, payload: polls }), [dispatch])
  const closePoll = useCallback(id => {
    dispatch({ type: UPDATE_POLL, payload: { ...polls.find(p => p.id === id), active: false } })
    socket.emit('close-vote', { id, room: sessionHelper.session.id })
  }, [dispatch, socket, sessionHelper, polls])

  useEffect(() => {
    if (!socket) {
      return;
    }
    socket.on('show-vote', onPollAdded)
    socket.on('votes', updatePolls)
    return () => {
      socket.off('votes', updatePolls)
      socket.off('show-vote', onPollAdded)
    }
  }, [socket, onPollAdded, updatePolls])

  return (
    <div className='p-0 h-100 w-100 d-flex flex-column align-items-center rounded text-light'>
      { !addingPoll && <React.Fragment>
        <Button className='mt-3 mb-2 w-100' onClick={() => setAddingPoll(true)}>Ajouter un sondage</Button>
        <Dropdown.Divider className='w-100' />
      </React.Fragment>}
      { addingPoll && <Form className='d-flex flex-column mb-3 w-100' action='#' onSubmit={createPoll}>
        <Form.Group className='mb-3 mt-3' controlId="question">
          <Form.Label>Question</Form.Label>
          <Form.Control ref={question} type='text' required />
        </Form.Group>
        <span>Options</span>
        {
          options.map((option, i) => {
            return <InputGroup key={i} className='mb-3'>
              <Form.Control key={i} type='text' required onChange={e => updateOption(i, e.target.value)} value={option} />
              <InputGroup.Append><Button onClick={() => removeOption(i)}><i className='fa fa-minus' aria-hidden='true' /></Button></InputGroup.Append>
            </InputGroup>
          })
        }
        <Button onClick={addOption} className='mb-3'><i className='fa fa-plus' aria-hidden='true' /></Button>
        <Button type='submit'>Créer le sondage</Button>
      </Form>}
      <Accordion className='h-100 w-100 mt-3 mb-3 p-0 d-flex flex-column list-group overflow-auto rounded col'>
        { polls.length === 0
          ? <span>Aucun sondage</span>
          : polls.map((poll, i) =>
            <Card key={poll.id} data-id={poll.id} className='w-100 flex-shrink-0'>
              <Card.Header>
                <Accordion.Toggle as={Button} variant='link' eventKey={i} className='w-100'>
                  {poll.question}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={i}>
                <Card.Body className={classnames({ 'bg-secondary': !poll.active })}>
                  {
                    poll.options.map((option, i) => <Row key={i} className='text-dark list-group-item d-flex justify-content-between bg-transparent'><span>{option}</span><span>{poll.answers[i].length}</span></Row>)
                  }
                  { poll.active && <Button className='mt-3 w-100' onClick={() => closePoll(poll.id)}>Clore le sondage</Button>}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            )
        }
      </Accordion>
    </div>
  )
}

export default Polls
Polls.propTypes = {
  socket: PropTypes.object,
  sessionHelper: PropTypes.object,
}