import React, { useRef, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Container, Col, Row } from 'react-bootstrap'
import io from 'socket.io-client'

import AdminControls from './admin/AdminControls'
import StreamColumn from './StreamColumn'
import Presentation from './Presentation'
import { useCallback } from 'react'
import { RPContext, SET_POLLS } from '../context/rp-context'

const API_URL = `${process.env.REACT_APP_SSL_ENABLED === 'true' ? 'https' : 'http'}://${process.env.REACT_APP_API_URI}:${process.env.REACT_APP_PORT}/`

const Admin = props => {
  const { sessionHelper, streams } = props;
  const { dispatch } = useContext(RPContext);
  const socket = useRef();

  const onSocketReady = useCallback(({ polls }) => {
    dispatch({ type: SET_POLLS, payload: polls })
  }, [dispatch])
  
  useEffect(() => {
    socket.current = io(API_URL, { query: { admin: true } });
    socket.current.on('ready', onSocketReady)
    return () => {
      socket.current.off('ready', onSocketReady)
    }
  }, [socket, onSocketReady]);

  return (
    <Container className='h-100 bg-white' fluid>
      <Row className='h-100'>
        <Col className='h-100 pt-3 pb-3 d-flex flex-column bg-light shadow' style={{ zIndex: 2 }} md={2}>{ socket.current && <AdminControls socket={socket.current} sessionHelper={sessionHelper} />}</Col>
        <Col className='h-100 bg-light' md={6}>{ sessionHelper && sessionHelper.session && <Presentation /> }</Col>
        <Col className='h-100 p-3 bg-light shadow' md={4}><StreamColumn socket={socket.current} sessionHelper={sessionHelper} streams={streams} isAdmin={true} /></Col>
      </Row>
    </Container>
  )
}

export default Admin
Admin.propTypes = {
  sessionHelper: PropTypes.object,
  streams: PropTypes.array,
}