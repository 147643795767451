import React, { useCallback, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Button, ButtonGroup } from 'react-bootstrap'
import classnames from 'classnames'
import { RPContext, VIEWER_REQUEST_SPEAK, SET_SPEAKER } from '../context/rp-context'

const Viewers = props => {
  const { session, isAdmin, socket } = props;
  const { state, dispatch } = useContext(RPContext);
  const { viewers, speaker } = state;

  const sortViewers = useCallback((a, b) => {
    if (speaker === a.id) {
      return -1
    }
    if (speaker === b.id) {
      return 1
    }
    if (a.requestingSpeak && !b.requestingSpeak) {
      return -1
    }
    if (!a.requestingSpeak && b.requestingSpeak) {
      return 1
    }
    return 0
  }, [speaker]);

  /**
   * Give the speech back to the admin
   */
  const resetSpeaker = useCallback(() => {
    if (speaker === null) {
      return
    }
    dispatch({ type: SET_SPEAKER, payload: null })
    socket.emit('stop-speak', { room: session.id });
  }, [dispatch, speaker, session, socket])

  /**
   * Grant the right to speak to the given user
   */
  const setSpeaker = useCallback(id => {
    const userConnection = session.connections.find(c => c.id === id);
    if (!userConnection) {
      return;
    }
    // If there was another speaker before, we stop him before starting the other one
    if (speaker !== null) {
      resetSpeaker()
    }

    dispatch({ type: VIEWER_REQUEST_SPEAK, payload: { id, request: false } });
    dispatch({ type: SET_SPEAKER, payload: id });
    socket.emit('allow-speak', { speaker: id, room: session.id });
  }, [dispatch, speaker, session, socket, resetSpeaker])

  const denySpeak = useCallback(id => {
    dispatch({ type: VIEWER_REQUEST_SPEAK, payload: { id, request: false } });
    socket.emit('deny-speak', { room: session.id, to: id });
    // session.signal({
    //   type: 'deny-speak',
    //   to: session.connections.find(c => c.id === id)
    // });
  }, [dispatch, session, socket])

  /**
   * When a user requests to speak
   */
  const onViewerRequestSpeak = useCallback(e => {
    dispatch({ type: VIEWER_REQUEST_SPEAK, payload: { id: e.from.id, request: true } })
  }, [dispatch])

  /**
   * When a user cancels his request to speak
   */
  const onViewerCancelRequestSpeak = useCallback(e => {
    dispatch({ type: VIEWER_REQUEST_SPEAK, payload: { id: e.from.id, request: false } })
    if (e.from.id === speaker) {
      resetSpeaker()
    }
  }, [dispatch, speaker, resetSpeaker])

  useEffect(() => {
    if (!session) {
      return;
    }
    session.on('signal:request-speak', onViewerRequestSpeak);
    session.on('signal:cancel-request-speak', onViewerCancelRequestSpeak);
    return () => {
      session.off('signal:cancel-request-speak', onViewerRequestSpeak);
      session.off('signal:cancel-request-speak', onViewerCancelRequestSpeak);
      
    }
  }, [session, onViewerRequestSpeak, onViewerCancelRequestSpeak])
  
  return (
    <Col className='rounded d-flex flex-grow-1 list-group p-0 overflow-auto' style={{ backgroundColor: '#526986' }}>
      { viewers.sort(sortViewers).map(viewer =>
        <li className={classnames('list-group-item d-flex justify-content-between align-items-center viewer-list-item', { active: viewer.isMe } )} style={{ cursor: 'pointer' }} key={viewer.id}>
          { viewer.isMe ? 'You' : viewer.name }
          { isAdmin && (viewer.requestingSpeak || speaker === viewer.id) && <React.Fragment>
                { 
                  viewer.requestingSpeak
                  ? <React.Fragment>
                      <span className='badge badge-primary badge-pill'>Souhaite parler</span>
                      <ButtonGroup className='buttons'>
                        <Button className='btn-success' onClick={() => setSpeaker(viewer.id)}><i className="fas fa-check"></i></Button>
                        <Button className='btn-danger' onClick={() => denySpeak(viewer.id)}><i className="fas fa-times"></i></Button>
                      </ButtonGroup>
                    </React.Fragment>
                  : <React.Fragment>
                      <span className='badge badge-primary badge-pill'>A la parole</span>
                      <ButtonGroup className='buttons'>
                        <Button className='btn-danger' onClick={() => resetSpeaker()}><i className="fas fa-times"></i></Button>
                      </ButtonGroup>
                    </React.Fragment>
                }
            </React.Fragment> }
          { !isAdmin && speaker === viewer.id && <span className='badge badge-primary badge-pill'>A la parole</span>}
        </li>
      ) }
    </Col>
  )
}

Viewers.propTypes = {
  session: PropTypes.object,
  socket: PropTypes.object,
  isAdmin: PropTypes.bool,
}
export default Viewers