import React from 'react'
import { Col } from 'react-bootstrap'

import '../fonts/stylesheet.css'
import bg from '../img/background.png'
import commemoratifs from '../img/commemoratifs.jpg'

const Page = () => {
  return (
    <Col style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', fontFamily: 'Avenir' }} className='h-100 overflow-auto bg-info text-white p-4'>
      <h1>Contexte clinique</h1>
      <hr/>
      <p>Au moment des épisodes de bronchite, un scanner NON injecté est réalisé. Il met en évidence plusieurs condensations périphériques.</p>
      <img src={commemoratifs} alt='Commemoratifs' />
    </Col>
  )
}

export default Page
